const list = [
    {
        order: 1,
        category: 'POSTER',
        name: 'poster',
        mainImg: '/images/product/POSTER-MAIN.webp',
        mobileMainImg: '/images/content-img-1.webp',
        imgType: '.webp',
        icon: '/images/icons/poster.webp',
        subLength: 5,
        title: '포스터' ,
        subTitle: '포스터 서브타이틀' ,
        desc: '포스터 | 패브릭 | 접지', 
    },
    {
        order: 2,
        category: 'BOOK',
        name: 'book',
        mainImg: '/images/product/BOOK-MAIN.webp',
        mobileMainImg: '/images/content-img-2.webp',
        imgType: '.webp',
        icon: '/images/icons/book.webp',
        subLength: 2,
        title: '북' ,
        subTitle: '북 서브타이틀' ,
        desc: '무선제본 | 양장제본 | 바인딩', 
    },
      {
        order: 3,
        category: 'LEAFLET',
        name: 'leaflet',
        mainImg: '/images/product/LEAFLET-MAIN.webp',
        mobileMainImg: '/images/content-img-3.webp',
        imgType: '.webp',
        icon: '/images/icons/leaflet.webp',
        subLength: 5,
        title: '리플렛' ,
        subTitle: '리플렛 서브타이틀' ,
        desc: '리플렛 | 각종 접지 | 후가공',  
    },
    {
        order: 4,
        category: 'BANNER',
        name: 'banner',
        mainImg: '/images/product/BANNER-MAIN.webp',
        mobileMainImg: '/images/content-img-4.webp',
        imgType: '.webp',
        icon: '/images/icons/banner.webp',
        subLength: 5,
        title: '배너' ,
        subTitle: '배너 서브타이틀' ,
        desc: '현수막 | 배너 | 대형 | 미니', 
    },
    {
        order: 5,
        category: 'SINGLE',
        name: 'single',
        mainImg: '/images/product/SINGLE-MAIN.webp',
        mobileMainImg: '/images/content-img-5.webp',
        imgType: '.webp',
        icon: '/images/icons/single.webp',
        subLength: 5,
        title: '싱글페이지' ,
        subTitle: '싱글페이지 서브타이틀' ,
        desc: '전단 | 홍보지 | 설문 | 양식', 
    },
      {
        order: 6,
        category: 'BRANDING',
        name: 'branding',
        mainImg: '/images/product/BRANDING-MAIN.webp',
        mobileMainImg: '/images/content-img-6.webp',
        imgType: '.webp',
        icon: '/images/icons/branding.webp',
        subLength: 1,
        title: '브랜딩' ,
        subTitle: '브랜딩 서브타이틀' ,
        desc: 'BI | LOGO | VARIATIONS',  
    },
    {
        order: 7,
        category: 'PROMOTION',
        name: 'promotion',
        mainImg: '/images/product/PROMOTION-MAIN.webp',
        mobileMainImg: '/images/content-img-7.webp',
        imgType: '.webp',
        icon: '/images/icons/promotion.webp',
        subLength: 2,
        title: '프로모션' ,
        subTitle: '프로모션 서브타이틀' ,
        desc: '쇼핑백 | 물티슈 | 굿지 | 홍보용품', 
    },
    {
        order: 8,
        category: 'SIGNBOARD',
        name: 'signboard',
        mainImg: '/images/product/SIGNBOARD-MAIN.webp',
        mobileMainImg: '/images/content-img-8.webp',
        imgType: '.webp',
        icon: '/images/icons/signboard.webp',
        subLength: 3,
        title: '사인보드' ,
        subTitle: '사인보드 서브타이틀' ,
        desc: '현판 | 문패 | 상패 | 인증패', 
    },
      {
        order: 9,
        category: 'BROCHURE',
        name: 'brochure',
        mainImg: '/images/product/BROCHURE-MAIN.webp',
        mobileMainImg: '/images/content-img-9.webp',
        imgType: '.webp',
        icon: '/images/icons/brochure.webp',
        subLength: 4,
        title: '브로셔' ,
        subTitle: '브로셔 서브타이틀' ,
        desc: '소책자 | 기획브로슈어 | 기업카다로그',  
    },
]

export default list;