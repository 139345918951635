import React, { Fragment } from 'react'
import Home from './Home';

function Content() {

  const test = "test";

  return (
    <Fragment>
        <Home />
    </Fragment>
  )
}

export default Content;